@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: AkkuratMono;
  src: local("Akkurat Mono"), url("./fonts/AM-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Pressura";
  src: url("./fonts/GT-Pressura-Mono-Medium-Trial.otf") format("opentype");
  /* Add additional font formats and their corresponding paths if needed */
}

@font-face {
  font-family: "Pressura_text";
  src: url("./fonts/GT-Pressura-Text-Trial.otf") format("opentype");
  /* Add additional font formats and their corresponding paths if needed */
}
@font-face {
  font-family: "FAIR-XYZ";
  src: url("./fonts/Fair/FAIR-Regular.woff2") format("woff2"),
    url("./fonts/Fair/FAIR-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "FAIR-XYZ";
  src: url("./fonts/Fair/FAIR-Semibold.woff2") format("woff2"),
    url("./fonts/Fair/FAIR-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "FAIR-XYZ";
  src: url("./fonts/Fair/FAIR-Bold.woff2") format("woff2"),
    url("./fonts/Fair/FAIR-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

.font-primary {
  font-family: "FAIR-XYZ";
}

.font-am {
  font-family: "AkkuratMono";
}

.font-pressura_text {
  font-family: "Pressura_text";
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
